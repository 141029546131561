import { axiosInstance } from '../api';
import { apiToCollectionType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { Collection, Roles } from '../types';

const BASE_PATH = 'collections';

/**
 * Retrieves a all collections for a user.
 * @returns A promise that resolves to an array of collections.
 * @throws Throws an error if an error occurs while loading the collections.
 */
export const getCollections = async (): Promise<Collection[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/`);

    return response.data.map((apiCollection: any) => apiToCollectionType(apiCollection));
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your collections.');
  }
};

/**
 * Retrieves a collection by its ID.
 * @param id - The ID of the collection.
 * @returns A promise that resolves to the requested collection.
 * @throws Throws an error if an error occurs while loading the collection.
 */
export const getCollection = async (id: string): Promise<Collection> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${id}`);
    return apiToCollectionType(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your collection.');
  }
};

/**
 * Creates a new collection for a given collection.
 * @param collection - The collection to create.
 * @returns A promise that resolves to the created collection.
 * @throws Throws an error if an error occurs while creating the collection.
 */
export const createCollection = async (name: string, description: string): Promise<Collection> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/`, { name, description });

    return apiToCollectionType(response.data);
  } catch (error) {
    let ex = apiExceptionHandler('An error occurred creating your collection.').message;

    if (ex.includes('already exists')) {
      ex = 'A collection with this name already exists. Please choose a different name.';
    }

    throw new Error(ex);
  }
};

/**
 * Updates an existing collection.
 * @param collectionId - The ID of the collection to update.
 * @param name - The new name of the collection.
 * @param description - The new description of the collection.
 * @param tags - The new array of tags associated with the collection.
 * @param archived - Indicates whether the collection is archived.
 * @param type - The type of the collection.
 * @returns A promise that resolves when the collection is successfully updated.
 * @throws Throws an error if an error occurs while updating the collection.
 */
export const updateCollection = async (collectionId: string, name: string, description: string): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${collectionId}`, {
      name,
      description
    });
  } catch (error) {
    let ex = apiExceptionHandler('An error occurred updating your collection.').message;

    if (ex.includes('already exists')) {
      ex = 'A collection with this name already exists. Please choose a different name.';
    }

    throw new Error(ex);
  }
};

/**
 * Deletes a collection by its ID.
 * @param collectionId - The unique identifier of the collection to delete
 * @throws {ApiException} If deletion fails due to network or server error
 * @returns Promise that resolves when deletion is successful
 */
export const deleteCollection = async (collectionId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${BASE_PATH}/${collectionId}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while deleting the collection.');
  }
};

/**
 * Adds a prompt to a specified collection.
 *
 * @param collectionId - The ID of the collection to which the prompt will be added.
 * @param promptId - The ID of the prompt to be added to the collection.
 * @returns A promise that resolves when the prompt has been successfully added to the collection.
 * @throws Will throw an error if the request fails.
 */
export const addPromptToCollection = async (collectionId: string, promptId: string): Promise<void> => {
  try {
    await axiosInstance.post(`${BASE_PATH}/${collectionId}/prompts/${promptId}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while adding the prompt to the collection.');
  }
};

export const removePromptFromCollection = async (collectionId: string, promptId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${BASE_PATH}/${collectionId}/prompts/${promptId}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while removing the prompt from the collection.');
  }
};

/**
 * Adds a user to a specified collection.
 *
 * @param {string} collectionId - The ID of the collection to which the user will be added.
 * @param {string} userId - The ID of the user to be added to the collection.
 * @returns {Promise<void>} - A promise that resolves when the user has been added to the collection.
 * @throws Will throw an error if the request fails.
 */
export const addUserToCollection = async (collectionId: string, userId: string, role: Roles): Promise<void> => {
  try {
    await axiosInstance.post(`${BASE_PATH}/${collectionId}/user/${userId}/add/${role}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while adding the user to the collection.');
  }
};

/**
 * Removes a user from a specified collection.
 *
 * @param {string} collectionId - The ID of the collection from which the user will be removed.
 * @param {string} userId - The ID of the user to be removed from the collection.
 * @returns {Promise<void>} A promise that resolves when the user has been removed from the collection.
 * @throws Will throw an error if the removal operation fails.
 */
export const removeUserFromCollection = async (collectionId: string, userId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${BASE_PATH}/${collectionId}/user/${userId}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while removing the user from the collection.');
  }
};

/**
 * Sets the role of a user in a specific collection.
 *
 * @param {string} collectionId - The ID of the collection.
 * @param {string} userId - The ID of the user.
 * @param {Roles} role - The role to be assigned to the user.
 * @returns {Promise<void>} A promise that resolves when the role is successfully set.
 * @throws Will throw an error if the request fails.
 */
export const setUserRoleInCollection = async (collectionId: string, userId: string, role: Roles): Promise<void> => {
  try {
    await axiosInstance.post(`${BASE_PATH}/${collectionId}/user/${userId}/role/${role}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while updating the user role in the collection.');
  }
};
